import { Route, Switch } from "react-router-dom"
import { useSafeHistory } from "libs"
import { NotFound, AccessDenied, AccessExpired } from "containers"
import { UnauthenticatedRoute, AuthenticatedRoute, LoaderFallback, ErrorBoundary, IframePopOutButton } from "components"
import { OAuthCallback } from "components/src/Integrations/OAuthCallback"
import { lazyWithForcedRefresh } from "libs"
import { Suspense } from "react"

const PresentCVDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "PresentCVDisplay" */ "Present/PresentCVDisplay")
)
const DigitalCVDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "DigitalCVDisplay" */ "DigitalCVDisplay/DigitalCVDisplay")
)
const TemplateDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "TemplateDisplay" */ "Template/TemplateDisplay/TemplateDisplay")
)
const BullhornEmbed = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "BullhornEmbed" */ "Bullhorn/BullhornEmbed")
)
const BullhornRedirect = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "BullhornRedirect" */ "Bullhorn/BullhornRedirect")
)

const PublicAddJob = lazyWithForcedRefresh(() => import(/* webpackChunkName: "PublicAddJob" */ "Jobs/PublicAddJob"))
const EntryPoint = lazyWithForcedRefresh(() => import(/* webpackChunkName: "EntryPoint" */ "EntryPoint/EntryPoint"))
const CustomEntryPoint = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "CustomEntryPoint" */ "EntryPoint/CustomEntryPoint")
)
const IntegrationPage = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "IntegrationPage" */ "Integrations/IntegrationPage")
)

export default function Routes() {
	const history = useSafeHistory()
	history.listen((location, action) => {
		window?.rg4js?.("trackEvent", { type: "pageView", path: location.pathname })
	})

	window.Intercom("update", {
		hide_default_launcher: true, // default no chat in iframe/isngle page
	})

	return (
		<ErrorBoundary>
			<Suspense fallback={<LoaderFallback />}>
				<Switch>
					<AuthenticatedRoute path="/singlepageview/application/:appId">
						<PresentCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/digitalcv/:appId">
						<DigitalCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/candidate/application/:appId">
						<PresentCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/candidate/digitalcv/:appId">
						<DigitalCVDisplay />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/addjob">
						<PublicAddJob />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/integration">
						<IntegrationPage />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/singlepageview/template/:templateId">
						<TemplateDisplay />
					</AuthenticatedRoute>
					<Route path="/oauthcallback">
						<OAuthCallback />
					</Route>
					<Route path="/entrypoint/custom/:platform/:endPoint">
						<CustomEntryPoint />
					</Route>
					<Route path="/entrypoint/:endPoint">
						<EntryPoint />
					</Route>
					<Route path="/bullhorn/:apiKey/:secret">
						<BullhornEmbed />
					</Route>
					<Route path="/bullhornredirect">
						<BullhornRedirect />
					</Route>
					<UnauthenticatedRoute path="/accessexpired">
						<AccessExpired />
					</UnauthenticatedRoute>
					<Route path="/accessDenied">
						<AccessDenied />
					</Route>
					<Route>
						<NotFound />
					</Route>
				</Switch>
			</Suspense>
			<IframePopOutButton />
		</ErrorBoundary>
	)
}
