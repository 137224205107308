import { MouseEventHandler, ReactNode } from "react"
import "./StandardButton.css"
import { BsArrowRepeat } from "react-icons/bs"

interface StandardButtonProps {
	disabled?: boolean
	variant?: "outline" | "primary"
	onClick?: MouseEventHandler
	children: ReactNode
	size?: "small" | "large" | "full-width"
	isLoading?: boolean
	className?: string
}

export function StandardButton({
	size = "small",
	disabled,
	variant,
	onClick,
	children,
	className,
	...props
}: StandardButtonProps & React.HTMLAttributes<HTMLButtonElement>) {
	return (
		<button
			disabled={disabled ?? false}
			className={`standard-button-${variant ?? "outline"} ${
				disabled ? "standard-button-disabled" : ""
			} standard-button-${size} ${className ?? ""}`}
			type="button"
			onClick={onClick}
			{...props}>
			{children}
		</button>
	)
}

export function NewStandardButton({
	size = "large",
	isLoading = false,
	disabled = false,
	variant = "primary",
	onClick,
	children,
	className,
	...props
}: StandardButtonProps & React.HTMLAttributes<HTMLButtonElement>) {
	return (
		<button
			disabled={disabled}
			className={`new-standard-button-${variant ?? "primary"} ${
				disabled ? "new-standard-button-disabled" : ""
			} standard-button-${size} ${className ?? ""}`}
			type="button"
			onClick={onClick}
			{...props}>
			{children} {isLoading && <BsArrowRepeat className="spinning" />}
		</button>
	)
}
