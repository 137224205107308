import { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import Routes from "./Routes"
import {
	useLogin,
	useLogo,
	setUpSignatureLogin,
	navigationAllowedOnSignature,
	hasExpiredSignatureLogin,
	tearDownSignatureLogin,
	SignatureInfo,
	FeaturesProvider,
	ConfigProvider,
} from "libs"
import { useQuery } from "libs"
import { AppContextProvider, useMountEffect, getToken, NavigationContextProvider } from "libs"
import type { User, Company, JWT } from "libs"
import { Auth } from "@aws-amplify/auth"
import { onError } from "libs"
import { AppSkeleton, TopLevel } from "components"
// import CookieConsent from 'react-cookie-consent'
import { config } from "./config"
import { Route, Switch } from "react-router-dom"
import SinglePageRoutes from "./SinglePageRoutes"

let nullCompany: Company = { name: null, id: null }

function App() {
	const { pathname } = useLocation()
	const query = useQuery()
	const sig = query.get("sig") as JWT
	const redirect = query.get("redirect")
	const page = query.get("page")
	const signatureInRedirect = redirect?.includes("sig=")
	const signatureProvided = sig != null && sig.length > 0
	const [user, setUser] = useState<User>({ isAuthenticated: signatureProvided, name: "" } as User)
	const [company, setCompany] = useState<Company>(nullCompany)
	const performLogin = useLogin({ responseOnly: true, setCompanyOverride: setCompany, setUserOverride: setUser })
	const history = useHistory()
	const { logo, setLogoAndSendToDb, fetchLogoFromDb } = useLogo()
	const baseNavbarLinks: { [linkName: string]: string } = {
		Home: "/cvs",
		"Add CVs": "/upload",
	}
	let navbarLinks = baseNavbarLinks
	if (company?.options?.flags?.fullJobDescription) {
		navbarLinks = { ...navbarLinks, Jobs: "/jobs" }
	}
	navbarLinks.Templates = "/templates"
	navbarLinks.Settings = "/settings" // add settings last
	const [isAuthenticating, setIsAuthenticating] = useState(true)
	const [navigationAllowed, setNavigationAllowed] = useState(sig?.length == null)
	const [signatureInfo, setSignatureInfo] = useState<SignatureInfo>({
		hasSignature: signatureProvided,
		expired: false,
		signatureValid: true, // assume valid until proven otherwise
	})
	// const isWindows: boolean = (window.navigator?.platform ?? window.navigator?.ospcu).indexOf("Win") !== -1

	useEffect(() => {
		// add listener to check if user login has expired when tab is brought back into focus
		const checkLogin = async () => {
			if (user.isAuthenticated) {
				if (signatureProvided) {
					if (hasExpiredSignatureLogin()) {
						tearDownSignatureLogin()
						window.location.reload()
					}
				} else {
					await getToken() // will perform logout and reload if user login has expired
				}
			}
		}
		document.addEventListener("visibilitychange", checkLogin)
		return () => {
			document.removeEventListener("visibilitychange", checkLogin)
		}
	}, [user?.isAuthenticated, signatureProvided])

	async function onLoad() {
		if (hasExpiredSignatureLogin()) {
			// stored signature is expired
			tearDownSignatureLogin()
		}
		if (signatureInRedirect) {
			return // don't try to login if a signature is in redirect
		}
		if (signatureProvided) {
			try {
				console.log("signature provided")
				// use signature based security
				const constructedResponse = setUpSignatureLogin(sig)
				if (constructedResponse != null) {
					await performLogin({ response: constructedResponse })
					setNavigationAllowed(navigationAllowedOnSignature(sig))
				} else {
					setSignatureInfo((sInfo) => ({ ...sInfo, signatureExpired: true }))
					setUser({ ...user, isAuthenticated: false })
				}
				return
			} catch (e) {
				if (e?.response?.status === 401 || e?.response?.status === 403) {
					console.log("signature invalid")
					// set inauthenticated and invalid to correctly display access denied page
					setSignatureInfo((sInfo) => ({ ...sInfo, signatureValid: false }))
					setUser({ ...user, isAuthenticated: false })
				} else {
					onError(e)
				}
			}
			return
		}
		console.log("no signature provided")
		// use user based security
		try {
			const response = await Auth.currentUserInfo()
			// I think either Auth.currentUserInfo() or Auth.currentAuthenticatedUser({bypassCache: true}) is ok to use here
			// Both seem to verify the user is logged in, but Auth.currentUserInfo()
			// returns only the user info, not the jwts, which is fine for our purposes
			// If changing the error needs to be 'No current user' or 'The user is not authenticated' respectively
			if (response !== null && response !== undefined) {
				await performLogin({ response })
				setNavigationAllowed(true)
			}
		} catch (e) {
			if (e !== "No current user") {
				onError(e)
			}
		}
	}

	useMountEffect(async () => {
		await onLoad()
		setIsAuthenticating(false)
	})

	useEffect(() => {
		if (logo == null) {
			fetchLogoFromDb(company)
		}
	}, [company])

	async function handleLogout() {
		await Auth.signOut()
		setUser({ isAuthenticated: false, name: "" } as User)
		setCompany(nullCompany)
		tearDownSignatureLogin()
		history.push("/login")
	}

	function logoLink() {
		return user.isAuthenticated ? config.homepage : "/"
	}

	return (
		<ConfigProvider config={config}>
			<FeaturesProvider company={company} user={user}>
				<NavigationContextProvider
					navigationAllowed={navigationAllowed}
					setNavigationAllowed={setNavigationAllowed}
					signatureInfo={signatureInfo}>
					<Switch>
						<Route
							path={[
								"/singlepageview",
								"/bullhorn",
								"/bullhornredirect",
								"/accessDenied",
								"/candidate",
								"/accessexpired",
								"/entrypoint",
								"/oauthcallback",
							]}>
							<TopLevel isAuthenticating={isAuthenticating}>
								<AppContextProvider
									value={{ user, setUser, company, setCompany }}
									logoValue={{ logo, setLogoAndSendToDb, fetchLogoFromDb }}>
									<SinglePageRoutes />
								</AppContextProvider>
							</TopLevel>
						</Route>
						<Route>
							<AppSkeleton
								navbar={user.isAuthenticated}
								navbarDisabled={pathname === "/onboarding" && page !== "6"}
								isAuthenticating={isAuthenticating}
								loadFunction={onLoad}
								logoLink={logoLink()}
								navbarLinks={navbarLinks}
								userName={user.name}
								logo={logo}
								handleLogout={handleLogout}>
								<AppContextProvider
									value={{ user, setUser, company, setCompany }}
									logoValue={{ logo, setLogoAndSendToDb, fetchLogoFromDb }}>
									<Routes />
								</AppContextProvider>
							</AppSkeleton>
						</Route>
					</Switch>
				</NavigationContextProvider>
			</FeaturesProvider>
		</ConfigProvider>
	)
}

export default App
