import { Route, Switch } from "react-router-dom"
import { useSafeHistory } from "libs"
import { Login, NotFound, ForgotPassword, ConfirmAccount } from "containers"
import { AuthenticatedRoute, UnauthenticatedRoute, LoaderFallback, ErrorBoundary, IframePopOutButton } from "components"
import { lazyWithForcedRefresh } from "libs"
import { Suspense } from "react"

const PresentCVDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "PresentCVDisplay" */ "Present/PresentCVDisplay")
)
const TemplateDisplay = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "TemplateDisplay" */ "Template/TemplateDisplay/TemplateDisplay")
)
const PresentCVTable = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "PresentCVTable" */ "Present/PresentCVTable")
)
const TemplateTable = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "TemplateTable" */ "Template/TemplateTable/TemplateTableMainPage")
)
const Settings = lazyWithForcedRefresh(() => import(/* webpackChunkName: "Admin" */ "Present/PresentSettings"))
const Upload = lazyWithForcedRefresh(() => import(/* webpackChunkName: "Upload" */ "Present/PresentUpload"))

const AddJob = lazyWithForcedRefresh(() => import(/* webpackChunkName: "AddJob" */ "Jobs/AddJob"))
const EditJob = lazyWithForcedRefresh(() => import(/* webpackChunkName: "EditJob" */ "Jobs/EditJob"))
const JobsTable = lazyWithForcedRefresh(() => import(/* webpackChunkName: "JobsTable" */ "Jobs/JobsTable"))

const OnboardingScreens = lazyWithForcedRefresh(
	() => import(/* webpackChunkName: "OnboardingScreens" */ "OnboardingScreens/OnboardingScreens")
)

export default function Routes() {
	const history = useSafeHistory()
	history.listen((location, action) => {
		window?.rg4js?.("trackEvent", { type: "pageView", path: location.pathname })
	})

	return (
		<ErrorBoundary>
			<Suspense fallback={<LoaderFallback />}>
				<Switch>
					<UnauthenticatedRoute exact path="/">
						<Login />
					</UnauthenticatedRoute>
					<UnauthenticatedRoute path="/login">
						<Login />
					</UnauthenticatedRoute>
					<AuthenticatedRoute path="/cvs/:roleId">
						<PresentCVTable />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/cvs">
						<PresentCVTable />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/onboarding">
						<OnboardingScreens />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/application/:appId">
						<PresentCVDisplay />
					</AuthenticatedRoute>
					<UnauthenticatedRoute path="/forgotpassword">
						<ForgotPassword />
					</UnauthenticatedRoute>
					<UnauthenticatedRoute path="/confirmaccount">
						<ConfirmAccount />
					</UnauthenticatedRoute>
					<AuthenticatedRoute path="/changepassword">
						<ConfirmAccount />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/settings">
						<Settings />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/upload">
						<Upload />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/jobs/addjob">
						<AddJob />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/jobs/:jobId">
						<EditJob />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/jobs">
						<JobsTable />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/templates/:loc/:id">
						<TemplateTable />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/templates/:loc">
						<TemplateTable />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/templates">
						<TemplateTable />
					</AuthenticatedRoute>
					<AuthenticatedRoute path="/template/:templateId">
						<TemplateDisplay />
					</AuthenticatedRoute>
					<Route>
						<NotFound />
					</Route>
				</Switch>
			</Suspense>
			<IframePopOutButton />
		</ErrorBoundary>
	)
}
