import { Dropdown } from "react-bootstrap"
import "./SignUpSelectField.css"
import { useState } from "react"

export interface SignUpSelectFieldProps {
	options: string[]
	placeholder: string
	label: string
	required?: boolean
	updateField?: (option) => void
	fieldName: string
}

export function SignUpSelectField({
	updateField,
	options,
	label,
	fieldName,
	required = false,
	placeholder,
}: SignUpSelectFieldProps) {
	const [show, setShow] = useState(false)
	const [selectedOption, setSelectedOption] = useState(placeholder)
	const allowToggle = ["select", "click", "rootClose", "keydown"]
	const handleSelect = (option: string) => {
		setSelectedOption(option)
		updateField({ [fieldName]: option })
	}
	return (
		<div className="sign-up-select">
			<label>
				{label} {required && <span style={{ color: "var(--ara-red" }}>{" *"}</span>}
			</label>
			<Dropdown
				show={show}
				onToggle={(shouldShow: boolean, metaData: { originalEvent; source }) => {
					const e = metaData.originalEvent
					if (allowToggle.includes(metaData.source)) {
						setShow(shouldShow)
					} else {
						console.log(metaData.source)
						if (e.key === " ") {
							console.log("space")
							e.preventDefault()
						}
					}
				}}>
				<Dropdown.Toggle className="sign-up-select-toggle">{selectedOption}</Dropdown.Toggle>
				<Dropdown.Menu className="sign-up-select-menu">
					{options.map((option) => (
						<Dropdown.Item onClick={() => handleSelect(option)} key={option}>
							{option}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}
