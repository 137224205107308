import whoopsIcon from "components/images/whoopsIcon.png"
import { useHistory } from "react-router-dom"
import { useQuery } from "libs"
import araLogo from "components/images/HireAraLogoPurple.png"

const DigitalCVExpired = ({ appId }) => {
	return <div>Please contact the original sender to provide a new link.</div>
}

const EXTRA_MESSAGES = {
	"/candidate/digitalcv": DigitalCVExpired,
	"/singlepageview/digitalcv": DigitalCVExpired,
	default: () => <></>,
}

export function AccessExpired() {
	const history = useHistory()
	const query = useQuery()

	const redirect = query.get("redirect") ?? ""
	const [redirectPath] = redirect.split("?")
	const redirectSplit = redirectPath.split("/")
	const redirectBase = redirectSplit.slice(0, 3).join("/")
	const appId = redirectSplit[3]

	const ExtraMessage = EXTRA_MESSAGES[redirectBase] ?? EXTRA_MESSAGES["default"]
	console.log(ExtraMessage)

	return (
		<div style={{ textAlign: "center" }}>
			<div style={{ margin: "20px 0 40px" }}>
				<img width={100} src={araLogo} alt="logo"></img>
			</div>
			<div className="purpleText" style={{ fontSize: 60, fontWeight: 700 }}>
				Whoops!
			</div>
			<h1>Access Expired</h1>
			<div>
				<ExtraMessage appId={appId} />
			</div>
			<img style={{ margin: 30 }} width={350} src={whoopsIcon} alt="whoops-img"></img>
			<div>
				If you have an account and wish to login as normal{" "}
				<span
					className="purpleText clickable"
					onClick={() => {
						history.push("/login")
					}}>
					click here
				</span>
				.
			</div>
		</div>
	)
}
