import { useContext, createContext, useState, useCallback, useRef, useMemo } from "react"
import { getFolders, applicationApi, templateApi, getJobs } from "../apiLib/apiLib"
import { makeReducedObjOfObj } from "../objLib/objLib"
import { App, Template, Folder, Company, CombinedUser, Job } from "../DatabaseObjects/DatabaseObjects"
import { ModalContextProvider } from "../Modal/ModalContext"
import { StickyStateOptions } from "../hooksLib/hooksLib.types"
import { AccessPermissions } from "../featuresLib/featuresLib"

interface TempContextProps {
	app: any
	setApp: any
	folder: any
	setFolder: any
	appLinking: any
	setAppLinking: any
	version: any
	setVersion: any
}
interface RecruiterDefaults {
	companyName: StickyStateOptions<string>
	jobTitle: StickyStateOptions<string>
	recruiterName: StickyStateOptions<string>
	recruiterEmail: StickyStateOptions<string>
	recruiterPhoto: StickyStateOptions<string>
	recruiterPhone?: StickyStateOptions<string>
}

interface AWSConfig {
	REGION: string
}

interface CognitoConfig extends AWSConfig {
	USER_POOL_ID: string
	APP_CLIENT_ID: string
	IDENTITY_POOL_ID?: string
}

interface S3Config extends AWSConfig {
	BUCKET: string
}

interface APIGateWayConfig extends AWSConfig {
	URL: string
	NAME: string
}
export interface ConfigObject {
	site: "admin" | "present"
	userType: "present"
	localDefaultSettings: RecruiterDefaults
	baseGetAppsParams: any
	baseGetTemplatesParams?: any
	storageKeys: any
	MAX_LOGO_SIZE: number
	includeLogoInPdf: boolean
	accessPermissions: AccessPermissions
	defaultUserRole?: string
	userRoles?: string[]
	createPresentRoleOnClients?: boolean
	homepage: `/${string}`
	captchaKey: string
	s3: S3Config
	apiGateway: APIGateWayConfig
	apiGatewayPublic?: APIGateWayConfig
	apiGatewayCustomPublic?: APIGateWayConfig
	cognito: CognitoConfig
}

interface LoadContextProps {
	user: CombinedUser
	setUser: (user: CombinedUser) => void
	apps: Indexed<App>
	setApps: (action: Action<App>) => Promise<Indexed<App>>
	templates: Indexed<Template>
	setTemplates: (action: Action<Template>) => Promise<Indexed<Template>>
	folders: Indexed<Folder>
	setFolders: (action: Action<Folder>) => Promise<Indexed<Folder>>
	jobs: Indexed<Job>
	setJobs: (action: Action<Job>) => Promise<Indexed<Job>>
	getAppForId: DataFinder<App>
	getTemplateForId: DataFinder<Template>
	getFolderForId: DataFinder<Folder>
	getJobForId: DataFinder<Job>
	company: Company
	setCompany: (company: Company) => void
}

type DataFinder<T extends object> = ({
	id,
	forceReload,
	forceDownload,
}: {
	id: string
	forceReload?: boolean
	forceDownload?: boolean
}) => Promise<Partial<T>>

export type Logo = string

interface LogoContextProps {
	logo: Logo
	// setLogo: any
	fetchLogoFromDb: any
	setLogoAndSendToDb: any
}

export const LoadContext = createContext<LoadContextProps>({} as LoadContextProps)
export const LogoContext = createContext<LogoContextProps>({} as LogoContextProps)
export const TempContext = createContext<TempContextProps>({} as TempContextProps)
export const ConfigContext = createContext<ConfigObject>({} as ConfigObject)

export const nullCompany = { name: null, id: null }
export const unAuthenticatedUser = { isAuthenticated: false, name: "" }

export function isUsefulData(obj) {
	if (!obj) {
		return false
	} else if (Object.keys(obj).length === 0) {
		return false
	}
	return true
}

export function storage<T extends any>({
	type,
	defaultReturn = {},
	specifyKeys = null,
	local = true,
}): [(id: string) => Promise<T>, (id: string, obj: any) => Promise<void>] {
	const store = local ? window.localStorage : window.sessionStorage
	if (specifyKeys) {
		return [
			async function (id) {
				return JSON.parse(store.getItem(`${type}_${id}`) ?? "{}") || defaultReturn
			},
			async function (id, obj) {
				store.setItem(`${type}_${id}`, JSON.stringify(makeReducedObjOfObj(obj, specifyKeys)))
			},
		]
	} else {
		return [
			async function (id) {
				return JSON.parse(store.getItem(`${type}_${id}`) ?? "{}") || defaultReturn
			},
			async function (id, obj) {
				store.setItem(`${type}_${id}`, JSON.stringify(obj))
			},
		]
	}
}

interface BaseAction<T> {
	for: string
	newData?: Indexed<T>
	cancel?: { current: boolean }
}

interface DownloadAction<T> extends BaseAction<T> {
	from: "download"
	downloadParams?: object
}

interface OverwriteAction<T> extends BaseAction<T> {
	from: "overwrite"
	newData: Indexed<T>
}

interface StorageAction<T> extends BaseAction<T> {
	from: "storage"
}

interface DownloadIfNotStoredAction<T> extends BaseAction<T> {
	from: "downloadIfNotStored"
}

interface SetStoredUpdateWithDownloadAction<T> extends BaseAction<T> {
	from: "setStoredUpdateWithDownload"
	afterFunc?: () => void
}

interface SingleItemAction<T> extends BaseAction<T> {
	from: "singleItem"
	itemId: string
	item: T
}

interface DeleteItemAction<T> extends BaseAction<T> {
	from: "deleteItem"
	itemId: string
}

interface PassThroughAction<T> extends BaseAction<T> {
	from: "passThrough"
	newData: Indexed<T>
}

type Action<T> =
	| DownloadAction<T>
	| OverwriteAction<T>
	| StorageAction<T>
	| DownloadIfNotStoredAction<T>
	| SetStoredUpdateWithDownloadAction<T>
	| SingleItemAction<T>
	| DeleteItemAction<T>
	| PassThroughAction<T>

export type Indexed<T> = {
	[key: string | number]: T
}

function downloadStorageReducer<T extends object>(
	downloadFunc: (name: string, params?: object) => Promise<Indexed<T>>,
	storageGetFunc: (name: string) => Promise<Indexed<T>>,
	storageSetFunc: (name: string, data: Indexed<T>) => Promise<void>
) {
	return async function reducer(
		state: Indexed<T>,
		action: Action<T>,
		hookSetFunc: ({ newData }: { newData: Indexed<T> }) => void
	): Promise<Indexed<T>> {
		switch (action.from) {
			case "download": {
				const newData = await downloadFunc(action.for, action.downloadParams)
				if (!action.cancel?.current) {
					await storageSetFunc(action.for, newData)
				} else {
					console.log("download cancelled")
				}
				return newData
			}
			case "overwrite": {
				await storageSetFunc(action.for, action.newData)
				return action.newData
			}
			case "storage": {
				return await storageGetFunc(action.for)
			}
			case "downloadIfNotStored": {
				const stored = await storageGetFunc(action.for)
				console.log(isUsefulData(stored) ? "setting from storage" : "setting from download")
				return isUsefulData(stored) ? stored : await downloadFunc(action.for)
			}
			case "setStoredUpdateWithDownload": {
				downloadFunc(action.for).then(async (response) => {
					console.log("setting after download")
					if (!action.cancel?.current) {
						hookSetFunc({ newData: response })
						await storageSetFunc(action.for, response)
					}
					if (action.afterFunc !== undefined) {
						action.afterFunc()
					}
				})
				console.log("setting from storage")
				return await storageGetFunc(action.for)
			}
			case "singleItem": {
				if (!state || Object.keys(state).length === 0) {
					// load from storage if poss.
					state = (await storageGetFunc(action.for)) ?? {}
				}
				state[action.itemId] = action.item
				storageSetFunc(action.for, state)
				return { ...state }
			}
			case "deleteItem": {
				delete state[action.itemId]
				storageSetFunc(action.for, state)
				console.log("single app deleted")
				return { ...state }
			}
			default: {
				return action.newData
			}
		}
	}
}

function useDataLoader<T extends object>(
	loadingFunction: (
		current: Indexed<T>,
		action: Action<T>,
		callback?: (action: { newData?: Indexed<T> }) => Promise<Indexed<T>>
	) => Promise<Indexed<T>>,
	init: Indexed<T>
): [Indexed<T>, (action: Action<T>) => Promise<Indexed<T>>, DataFinder<T>] {
	//Expects the data to be in the form {id:item}, with a 'download' action available
	var [data, setDataBase] = useState<Indexed<T>>(init)
	const dataRef = useRef(data)
	const setData = useCallback((newData: Indexed<T>) => {
		dataRef.current = newData
		setDataBase(newData)
	}, [])
	const setLoadedData = useCallback(
		async function (action: Action<T>) {
			const newData: Indexed<T> = await loadingFunction(dataRef.current, action, setLoadedData)
			if (!action.cancel?.current) {
				setData(newData)
			}
			return newData
		},
		[loadingFunction, setData]
	)

	const dataFinder: DataFinder<T> = useCallback(
		async ({ id, forceReload = false, forceDownload = false }) => {
			if (id === undefined) {
				return {}
			}
			if (!forceReload && id in dataRef.current) {
				return dataRef.current?.[id]
			} else if (forceDownload) {
				const parent_id = id.split("_").slice(0, -1).join("_")
				var newData = await setLoadedData({ from: "setStoredUpdateWithDownload", for: parent_id })
				if (id in newData) {
					return newData[id]
				} else {
					// //What to do if id doesn't exist in downloaded data? Error for now
					// throw Error('id does not exist in downloaded data')
					return {}
				}
			} else {
				const parent_id = id.split("_").slice(0, -1).join("_")
				var newData = await setLoadedData({ from: "downloadIfNotStored", for: parent_id })
				if (id in newData) {
					return newData[id]
				} else {
					// //What to do if id doesn't exist in downloaded data? Error for now
					// throw Error('id does not exist in downloaded data')
					return {}
				}
			}
		},
		[setLoadedData]
	)

	return [data, setLoadedData, dataFinder]
}

// function useObject(initValue) {
//   const [obj, setObj] = useState(initValue)
//   function setter(newKey, newVal, ignoreNull = false) {
//     if (ignoreNull && newVal == null) {
//       return
//     }
//     obj[newKey] = newVal
//     setObj(obj)
//   }
//   return [obj, setter]
// }

export const ConfigProvider = ({ children, config }) => {
	return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

interface AppContextProviderProps {
	value: Pick<LoadContextProps, "user" | "setUser" | "company" | "setCompany">
	tempValue?: TempContextProps
	logoValue: LogoContextProps
	overwriteValue?: object
	overwriteTempValue?: object
	children: React.ReactNode
}

export function AppContextProvider({
	value,
	tempValue,
	logoValue,
	overwriteValue,
	overwriteTempValue,
	...props
}: AppContextProviderProps) {
	const config = useConfigContext()
	const foldersReducer = useMemo(
		() => downloadStorageReducer<Folder>(getFolders, ...storage<Indexed<Folder>>({ type: "folders" })),
		[]
	)
	const jobsReducer = useMemo(
		() => downloadStorageReducer<Job>(getJobs, ...storage<Indexed<Job>>({ type: "jobs" })),
		[]
	)
	const templatesReducer = useMemo(
		() =>
			downloadStorageReducer<Template>(
				(clientId, params) =>
					templateApi.get({ clientId, ...config.baseGetTemplatesParams, ...params }) as Promise<Indexed<Template>>,
				...storage<Indexed<Template>>({ type: "templates" })
			),
		[config.baseGetTemplatesParams]
	)
	const appsReducer = useMemo(
		() =>
			downloadStorageReducer<App>(
				(folderId, params) =>
					applicationApi.get({ folderId, ...config.baseGetAppsParams, ...params }) as Promise<Indexed<App>>,
				...storage<Indexed<App>>({ type: "apps", specifyKeys: config.storageKeys.application, local: false })
			),
		[config.baseGetAppsParams, config.storageKeys.application]
	)
	const [folders, setFolders, getFolderForId] = useDataLoader<Folder>(foldersReducer, {})
	const [apps, setApps, getAppForId] = useDataLoader<App>(appsReducer, {})
	const [templates, setTemplates, getTemplateForId] = useDataLoader<Template>(templatesReducer, {})
	const [jobs, setJobs, getJobForId] = useDataLoader<Job>(jobsReducer, {})
	const [folder, setFolder] = useState()
	const [app, setApp] = useState()
	const [appLinking, setAppLinking] = useState()
	const [version, setVersion] = useState()
	return (
		<LogoContext.Provider value={{ ...logoValue }}>
			<TempContext.Provider
				value={{
					...tempValue,
					version,
					setVersion,
					app,
					setApp,
					folder,
					setFolder,
					appLinking,
					setAppLinking,
					...overwriteTempValue,
				}}>
				<LoadContext.Provider
					value={{
						...value,
						folders,
						setFolders,
						getFolderForId,
						jobs,
						setJobs,
						getJobForId,
						apps,
						setApps,
						getAppForId,
						templates,
						setTemplates,
						getTemplateForId,
						...overwriteValue,
					}}>
					<ConfigContext.Provider value={{ ...config }}>
						<ModalContextProvider>{props.children}</ModalContextProvider>
					</ConfigContext.Provider>
				</LoadContext.Provider>
			</TempContext.Provider>
		</LogoContext.Provider>
	)
}

export function useAppContext() {
	return {
		...useLoadContext(),
		...useTempContext(),
		...useLogoContext(),
		//  ...useConfigContext() }
	}
}

export function useTempContext() {
	const context = useContext(TempContext)
	return context
}

export function useConfigContext() {
	return useContext(ConfigContext)
}

export function useLoadContext() {
	return useContext(LoadContext)
}

export function useLogoContext() {
	return useContext(LogoContext)
}

export function AppContextConsumer({ children }) {
	return (
		<LoadContext.Consumer>
			{(loadArgs) => (
				<TempContext.Consumer>
					{(logoArgs) => (
						<LogoContext.Consumer>
							{(tempArgs) => (
								<ConfigContext.Consumer>
									{(configArgs) => children({ ...tempArgs, ...loadArgs, ...logoArgs, ...configArgs })}
								</ConfigContext.Consumer>
							)}
						</LogoContext.Consumer>
					)}
				</TempContext.Consumer>
			)}
		</LoadContext.Consumer>
	)
}
