import { retrieveStickyValue, addStickyValue, deleteStickyValue } from "../hooksLib/hooksLib"

export type JWT = `${string}.${string}.${string}`

export interface SignatureInfo {
	hasSignature: boolean
	signatureValid: boolean
	expired: boolean
}

export function storeSignature(sig: JWT) {
	addStickyValue({ storageName: "signature", value: sig, sessionOnly: true, userId: "noUser" })
}

export function retrieveSignature(): JWT | null {
	const sig = retrieveStickyValue({ storageName: "signature", sessionOnly: true, userId: "noUser" })
	if (sig) {
		return sig
	}
	return null
}

export function parseJwt(token: JWT) {
	var base64Url = token.split(".")[1]
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join("")
	)

	return JSON.parse(jsonPayload)
}

export function getLinkId(sig) {
	const claims = parseJwt(sig)
	return claims.uid
}

export function setUpSignatureLogin(sig: JWT) {
	const claims = parseJwt(sig)
	if (checkExpiry(claims)) {
		storeSignature(sig)
	} else {
		console.log("Signature has expired")
		return null
	}

	const response = {
		username: claims.sub,
		attributes: {
			...claims,
		},
	}
	return response
}

export function navigationAllowedOnSignature(sig: JWT) {
	const claims = parseJwt(sig)
	return claims.navigationAllowed
}

export function hasExpiredSignatureLogin(): boolean {
	const sig = retrieveSignature()
	if (!sig) {
		return false
	}
	const claims = parseJwt(sig)
	return !checkExpiry(claims)
}

export function tearDownSignatureLogin() {
	deleteStickyValue({ storageName: "signature", sessionOnly: true, userId: "noUser" })
}

export function checkExpiry(claims: object) {
	const expiry = claims["exp"]
	const now = Date.now() / 1000
	if (expiry > now) {
		return true
	}
	return false
}
