import { Route, Redirect, useLocation } from "react-router-dom"
import { useLoadContext, useSafeHistory } from "libs"

export function AuthenticatedRoute({ children, ...rest }) {
	const { pathname, search } = useLocation()
	const { user } = useLoadContext()
	const { signatureInfo } = useSafeHistory()

	const basePath = signatureInfo?.hasSignature
		? signatureInfo?.signatureValid
			? "/accessExpired" // signature expired (only possibility if user is not authenticated but the signature hasn't yet been marked as invalid)
			: "/accessDenied" // signature invalid
		: "/login"

	return (
		<Route {...rest}>
			{user.isAuthenticated ? children : <Redirect to={`${basePath}?redirect=${pathname}${search}`} />}
		</Route>
	)
}
